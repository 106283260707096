<h1 class="text-size-48 text-bold margin-top-0x">Diálogos/Modais</h1>
<p class="text-size-24 text-regular margin-bottom-16x">Fornece componentes que sobrepõem a página para realizar alguma interação com o usuário.</p>

<h2 class="text text-size-36 text-medium">Exemplos</h2>
<p class="text-size-18">Clique nos botões para abrir as janelas de diálogo.</p>

<div class="margin-bottom-8x">
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x margin-right-5x"
    aria-hidden="false"
    aria-label="Carregar Dados"
    (click)="onClickLoadData()">
    <label>Carregar Dados</label>
  </button>
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular background-red color-white height-10x margin-right-5x"
    aria-hidden="false"
    aria-label="Excluir Produto"
    (click)="onClickDeleteProduct()">
    <label>Excluir Produto</label>
  </button>
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x margin-right-5x"
    aria-hidden="false"
    aria-label="Olá"
    (click)="onClickHello()">
    <label>Olá</label>
  </button>
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x margin-right-5x"
    aria-hidden="false"
    aria-label="Mover Produto para Quarentena"
    (click)="onClickMoveProductToQuarantine()">
    <label>Mover Produto para Quarentena</label>
  </button>
</div>

<mat-card>
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="ts" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>
