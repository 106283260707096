<div class="margin-bottom-8x">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <button
      mat-flat-button
      type="button"
      class="button text-uppercase text-regular height-10x margin-bottom-3x"
      (click)="onEmitValidationError()">
      <label>Emitir erro de validação</label>
    </button>

    <div>
      <ui-form-field
        label="GTIN"
        [submitted]="submitted"
        [errors]="{
            invalid: ''
          }">
        <mat-icon
          [inline]="true"
          class="text-size-18 margin-left-1x color-emerald absolute top-negative-1x"
          icon>check_circle</mat-icon>
        <input
            matInput
            name="gtin"
            id="gtin"
            formControlName="gtin"
            input>
    </ui-form-field>
    </div>

    <div class="flex centered vertical">
      <hr class="grow separator">
      <button
        mat-flat-button
        type="submit"
        class="button text-uppercase text-regular height-10x margin-left-3x"
        color="accent">
        <label>Salvar</label>
      </button>
    </div>

  </form>
</div>

<mat-card class="margin-bottom-4x">
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="ts" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card>
  <h4>Propriedades</h4>
  <div class="table-container">
    <table class="mat-table">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Tipo</th>
          <th>Padrão</th>
          <th>Descrição</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>label<strong>*</strong></td>
          <td>string</td>
          <td>null</td>
          <td>Rótulo do campo do formulário</td>
        </tr>
        <tr>
          <td>submitted</td>
          <td>boolean</td>
          <td>false</td>
          <td>Variável de controle se o formulário foi submetido</td>
        </tr>
        <tr>
          <td>required</td>
          <td>boolean</td>
          <td>false</td>
          <td>Adiciona "*" na frente do rótulo caso seja true</td>
        </tr>
        <tr>
          <td>classNames</td>
          <td>string[]</td>
          <td>['input-outline']</td>
          <td>Adiciona classes CSS ao mat-form-field</td>
        </tr>
        <tr>
          <td>errors</td>
          <td>Object</td>
          <td>{{ '{}' }}</td>
          <td>Define mensagens de erro. Exemplo: {{ '{required: \'Obrigatório\'}' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-card>
