<h1 class="text-size-48 text-bold margin-top-0x">Loaders</h1>
<p class="text-size-24 text-regular margin-bottom-16x">Componentes que indicam ao usuário que a página está carregando.</p>

<h2 class="text text-size-36 text-medium">Exemplos</h2>
<p class="text-size-18">Os loaders podem ser adaptados para diversas telas.</p>

<div class="margin-bottom-8x flex">
  <div class="example-container margin-right-3x">
    <ui-loader></ui-loader>
  </div>
  <div class="example-container margin-right-3x">
    <!-- Works if parent element position is defined. -->
    <ui-loader [forcePositionCenter]="true"></ui-loader>
  </div>
  <div class="example-container">
    <!-- Works if parent element height is defined. -->
    <ui-loader [fullHeight]="true"></ui-loader>
  </div>
</div>

<mat-card>
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="ts" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="SCSS"><pre><code [highlight]="scss" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>
