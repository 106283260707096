<h1 class="text-size-48 text-bold margin-top-0x">Alertas</h1>
<p class="text-size-24 text-regular margin-bottom-16x">Fornece mensagens de feedback contextual para ações do usuário.</p>

<h2 class="text text-size-36 text-medium">Exemplos</h2>
<p class="text-size-18">Os alertas estão disponíveis para qualquer tamanho de texto.</p>

<div class="margin-bottom-8x">
  <ui-alert-danger title="Perigo" message="Este é um alerta de perigo!" *ngIf="showAlertDanger" (closeAlert)="showAlertDanger = false" class="margin-bottom-3x"></ui-alert-danger>
  <ui-alert-success title="Sucesso" message="Este é um alerta de sucesso!"  class="margin-bottom-3x"></ui-alert-success>
  <ui-alert-warning title="Aviso" message="Este é um alerta de aviso!" *ngIf="showAlertWarning" (closeAlert)="showAlertWarning = false"></ui-alert-warning>
</div>

<mat-card>
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="ts" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>
