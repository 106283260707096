import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

import { FormFieldComponent } from './form-field/form-field.component';



@NgModule({
  declarations: [FormFieldComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    FormFieldComponent
  ]
})
export class FormModule { }
