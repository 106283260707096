<h1 class="text-size-48 text-bold margin-top-0x">Form Fields</h1>
<p class="text-size-24 text-regular margin-bottom-16x">Componentes utilizados para montagem de formulários.</p>

<h2 class="text text-size-36 text-medium">Exemplos</h2>
<p class="text-size-18">Clique no botão para alterar entre o estado habilidato/desabilitado.</p>

<div class="margin-bottom-8x">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <button
      mat-flat-button
      type="button"
      class="button text-uppercase text-regular height-10x margin-bottom-3x"
      (click)="onClickToggleDisabled()">
      <label>{{ form.disabled ? 'Habilitar': 'Desabilitar' }} Formulário</label>
    </button>

    <div>
      <label class="title">GTIN</label>
      <mat-form-field appearance="outline" class="input-outline">
        <input
            matInput
            name="gtin"
            id="gtin"
            formControlName="gtin">
      </mat-form-field>
    </div>

    <div class="flex centered vertical">
      <hr class="grow separator">
      <button
        mat-flat-button
        type="submit"
        class="button text-uppercase text-regular height-10x margin-left-3x"
        color="accent"
        [disabled]="form.disabled">
        <label>Salvar</label>
      </button>
    </div>

  </form>
</div>

<mat-card>
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="ts" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>

<p class="text-size-18 margin-top-10x">Componente <strong>ui-form-field</strong></p>
<app-form-field></app-form-field>
