<form
  [formGroup]="checkboxForm"
  #form="ngForm"
  (ngSubmit)="onSubmit(form)">

  <h1
    mat-dialog-title
    class="color-orange-dark text-regular"
    *ngIf="data.title">{{ data.title }}</h1>

  <div mat-dialog-content>
    <ui-alert-danger
      title="Erro"
      i18n-title="@@error"
      message="Selecione ao menos uma opção antes de salvar."
      i18n-message="@@selectAtLeastOneOptionBeforeSaving"
      *ngIf="showAlertDanger"
      (closeAlert)="showAlertDanger = false"
      class="margin-bottom-3x"></ui-alert-danger>

    <p
      *ngIf="data.message"
      [innerHTML]="data.message"
      class="text-size-16 margin-top-3x margin-bottom-3x"></p>

    <div class="margin-bottom-5x">
      <mat-checkbox
        *ngFor="let checkbox of data.checkboxes"
        [formControlName]="checkbox.value"
        [matTooltip]="checkbox.tooltip"
        matTooltipPosition="before">
        {{ checkbox.label }}
      </mat-checkbox>
    </div>
  </div>
  <div mat-dialog-actions class="spaced">
    <button mat-flat-button
      type="button"
      class="button text-uppercase text-medium padding-left-11x padding-right-11x"
      aria-hidden="false"
      aria-label="Cancelar"
      (click)="onClickClose()">
      <label i18n="@@cancel">Cancelar</label>
    </button>
    <button mat-flat-button
      type="submit"
      class="button text-uppercase text-medium background-green color-white padding-left-11x padding-right-11x"
      color="accent"
      aria-hidden="false"
      aria-label="Salvar">
      <label i18n="@@save">Salvar</label>
    </button>
  </div>
</form>
