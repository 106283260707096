import { Component } from '@angular/core';
import {
  SELECT_BLUE_ANIMATION,
  SELECT_RED_ANIMATION,
  SELECT_PRIMARY_GRADIENT_ANIMATION,
  ROTATE_90_RIGHT_ANIMATION,
  SLIDE_UP_DOWN_ANIMATION,
  SLIDE_LEFT_RIGHT_ANIMATION,
  SHOW_HIDE_ANIMATION,
} from 'components';

@Component({
  selector: 'app-animations',
  templateUrl: './animations.component.html',
  styleUrls: ['./animations.component.scss'],
  animations: [
    SELECT_BLUE_ANIMATION,
    SELECT_RED_ANIMATION,
    SELECT_PRIMARY_GRADIENT_ANIMATION,
    ROTATE_90_RIGHT_ANIMATION,
    SLIDE_UP_DOWN_ANIMATION,
    SLIDE_LEFT_RIGHT_ANIMATION,
    SHOW_HIDE_ANIMATION,
  ],
})
export class AnimationsComponent {
  card1Selected = false;
  card2Selected = false;
  card3Selected = false;

  showSquare = false;

  showContent = false;

  contentIndex = 0;

  example1Html = `  <div class="flex">
    <div class="example-container margin-right-3x">
      <p class="text-bold">Azul</p>
      <mat-card class="flex centered vertical" [@selectBlue]="card1Selected" (click)="card1Selected = !card1Selected">
        <div class="width-40p">
          <p class="text-bold text-size-14 margin-bottom-2x margin-top-0x">GTIN</p>
          <p class="text-bold text-size-14 margin-bottom-2x">Descrição</p>
          <p class="text-bold text-size-14 margin-bottom-2x">Marca</p>
          <p class="text-bold text-size-14 margin-bottom-0x">Categoria</p>
        </div>
        <div class="width-60p">
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1 margin-top-0x">79011111111134</p>
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café Simplus 100</p>
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Simplus</p>
          <p class="text-size-14 margin-bottom-0x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café</p>
        </div>
      </mat-card>
    </div>
    <div class="example-container margin-right-3x">
      <p class="text-bold">Vermelho</p>
      <mat-card class="flex centered vertical" [@selectRed]="card2Selected" (click)="card2Selected = !card2Selected">
        <div class="width-40p">
          <p class="text-bold text-size-14 margin-bottom-2x margin-top-0x">GTIN</p>
          <p class="text-bold text-size-14 margin-bottom-2x">Descrição</p>
          <p class="text-bold text-size-14 margin-bottom-2x">Marca</p>
          <p class="text-bold text-size-14 margin-bottom-0x">Categoria</p>
        </div>
        <div class="width-60p">
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1 margin-top-0x">79011111111134</p>
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café Simplus 100</p>
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Simplus</p>
          <p class="text-size-14 margin-bottom-0x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café</p>
        </div>
      </mat-card>
    </div>
    <div class="example-container margin-right-3x">
      <p class="text-bold">Primária</p>
      <mat-card class="flex centered vertical" [@selectPrimaryGradient]="card3Selected" (click)="card3Selected = !card3Selected">
        <div class="width-40p">
          <p class="text-bold text-size-14 margin-bottom-2x margin-top-0x">GTIN</p>
          <p class="text-bold text-size-14 margin-bottom-2x">Descrição</p>
          <p class="text-bold text-size-14 margin-bottom-2x">Marca</p>
          <p class="text-bold text-size-14 margin-bottom-0x">Categoria</p>
        </div>
        <div class="width-60p">
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1 margin-top-0x">79011111111134</p>
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café Simplus 100</p>
          <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Simplus</p>
          <p class="text-size-14 margin-bottom-0x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café</p>
        </div>
      </mat-card>
    </div>
  </div>
  `;
  example1Ts = `  import { Component } from '@angular/core';
  import { SELECT_BLUE_ANIMATION, SELECT_RED_ANIMATION, SELECT_PRIMARY_GRADIENT_ANIMATION } from 'simplus-components';

  @Component({
    selector: 'animation-example',
    templateUrl: 'animation-example.html',
    styleUrls: ['./animation-example.scss'],
    animations: [SELECT_BLUE_ANIMATION, SELECT_RED_ANIMATION, SELECT_PRIMARY_GRADIENT_ANIMATION],
  })
  export class AnimationExample {
    card1Selected = false;
    card2Selected = false;
    card3Selected = false;
  }`;
  example1Scss = `  .example-container {
    width: 320px;

    mat-card {
      cursor: pointer;
    }
  }`;

  example2Html = `  <div class="flex">
    <button
      mat-flat-button
      type="button"
      class="button text-uppercase text-regular height-10x margin-bottom-3x"
      (click)="showSquare = !showSquare">
      <label>{{ showSquare ? 'Ocultar' : 'Mostrar' }}</label>
    </button>
    <div class="example-square-container padding-3x" *ngIf="showSquare" [@showHide]>
      Surpresa
    </div>
  </div>`;
  example2Ts = `  import { Component } from '@angular/core';
  import { SHOW_HIDE_ANIMATION } from 'simplus-components';

  @Component({
    selector: 'animation-example',
    templateUrl: 'animation-example.html',
    styleUrls: ['./animation-example.scss'],
    animations: [SHOW_HIDE_ANIMATION],
  })
  export class AnimationExample {
    showSquare = false;
  }`;
  example2Scss = `  .example-square-container {
    width: 150px;
    height: 150px;
    background: lemonchiffon;
  }
  `;

  example3Html = `  <div class="flex">
    <div class="example-container margin-right-3x">
      <mat-card (click)="showContent = !showContent">
        <mat-icon
          aria-hidden="false"
          aria-label="Expandir"
          matTooltip="Expandir"
          class="icon margin-right-3x"
          [inline]="true"
          [@rotate90Right]="showContent">keyboard_arrow_right</mat-icon>
        Expandir
      </mat-card>
      <div *ngIf="showContent" [@slideUpDown] class="example-expansion-container padding-3x">
        Surpresa
      </div>
    </div>
  </div>
  `;
  example3Ts = `  import { Component } from '@angular/core';
  import { ROTATE_90_RIGHT_ANIMATION, SLIDE_UP_DOWN_ANIMATION } from 'simplus-components';

  @Component({
    selector: 'animation-example',
    templateUrl: 'animation-example.html',
    styleUrls: ['./animation-example.scss'],
    animations: [ROTATE_90_RIGHT_ANIMATION, SLIDE_UP_DOWN_ANIMATION],
  })
  export class AnimationExample {
    showContent = false;
  }`;
  example3Scss = `  .example-container {
    width: 320px;

    mat-card {
      cursor: pointer;
    }
  }

  .example-expansion-container {
    width: 320px;
    height: 480px;
    background: lemonchiffon;
  }
  `;

  example4Html = `  <div>
    <div class="example-slider-container margin-bottom-3x padding-3x" [@slideLeftRight]="contentIndex">
      <div class="example-slide-container example-slide-container-1" *ngIf="contentIndex === 0">AAAA</div>
      <div class="example-slide-container example-slide-container-2" *ngIf="contentIndex === 1">BBBB</div>
      <div class="example-slide-container example-slide-container-3" *ngIf="contentIndex === 2">CCC</div>
    </div>

    <button
      mat-flat-button
      type="button"
      class="button text-uppercase text-regular height-10x margin-right-5x"
      (click)="onClicPrevious()"
      [disabled]="disablePrevious()">
      <label>Anterior</label>
    </button>
    <button
      mat-flat-button
      type="button"
      class="button text-uppercase text-regular height-10x"
      (click)="onClickNext()"
      [disabled]="disableNext()">
      <label>Proxímo</label>
    </button>
  </div>
  `;
  example4Ts = `  import { Component } from '@angular/core';
  import { SLIDE_LEFT_RIGHT_ANIMATION } from 'simplus-components';

  @Component({
    selector: 'animation-example',
    templateUrl: 'animation-example.html',
    styleUrls: ['./animation-example.scss'],
    animations: [SLIDE_LEFT_RIGHT_ANIMATION],
  })
  export class AnimationExample {
    contentIndex = 0;

    disablePrevious(): boolean {
      return this.contentIndex === 0;
    }

    onClicPrevious(): void {
      this.contentIndex--;
    }

    disableNext(): boolean {
      return this.contentIndex === 2;
    }

    onClickNext(): void {
      this.contentIndex++;
    }
  }`;
  example4Scss = `  .example-slider-container {
    width: 320px;
    height: 480px;
    background: lemonchiffon;
    position: relative;
    overflow-x: hidden;
    // position & overflow-x são necessários para que a animação funcione corretamente.
  }
  `;

  disablePrevious(): boolean {
    return this.contentIndex === 0;
  }

  onClicPrevious(): void {
    this.contentIndex--;
  }

  disableNext(): boolean {
    return this.contentIndex === 2;
  }

  onClickNext(): void {
    this.contentIndex++;
  }
}
