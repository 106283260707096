import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import {
  AlertModule,
  DialogModule,
  LoaderModule,
  ScrollbarModule,
  FormModule,
} from 'components';
import { MaterialModule } from './module/material.module';
import { AlertsComponent } from './component/alerts/alerts.component';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { DialogsComponent } from './component/dialogs/dialogs.component';
import { LoadersComponent } from './component/loaders/loaders.component';
import { ScrollbarComponent } from './component/scrollbar/scrollbar.component';
import { AnimationsComponent } from './component/animations/animations.component';
import { FormFieldsComponent } from './component/form-fields/form-fields.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldComponent } from './component/form-fields/form-field/form-field.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertsComponent,
    DialogsComponent,
    LoadersComponent,
    ScrollbarComponent,
    AnimationsComponent,
    FormFieldsComponent,
    FormFieldComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HighlightModule,
    AlertModule,
    DialogModule,
    LoaderModule,
    ScrollbarModule,
    FormModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'),
      },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
