import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() forcePositionCenter = false;
  @HostBinding('class.full_height') @Input() fullHeight = false;
}
