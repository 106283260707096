import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  LoaderDialogComponent,
  ConfirmationDialogComponent,
  MessageDialogComponent,
  CheckboxListDialogComponent,
} from 'components';

@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.scss'],
})
export class DialogsComponent {
  html = `  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x margin-right-5x"
    aria-hidden="false"
    aria-label="Carregar Dados"
    (click)="onClickLoadData()">
    <label>Carregar Dados</label>
  </button>
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular background-red color-white height-10x margin-right-5x"
    aria-hidden="false"
    aria-label="Excluir Produto"
    (click)="onClickDeleteProduct()">
    <label>Excluir Produto</label>
  </button>
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x margin-right-5x"
    aria-hidden="false"
    aria-label="Olá"
    (click)="onClickHello()">
    <label>Olá</label>
  </button>
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x margin-right-5x"
    aria-hidden="false"
    aria-label="Mover Produto para Quarentena"
    (click)="onClickMoveProductToQuarantine()">
    <label>Mover Produto para Quarentena</label>
  </button>
  `;
  ts = `  import { Component } from '@angular/core';
  import { MatDialog } from '@angular/material/dialog';
  import { LoaderDialogComponent } from 'simplus-components';
  import { ConfirmationDialogComponent } from 'simplus-components';

  @Component({
    selector: 'dialog-example',
    templateUrl: 'dialog-example.html',
  })
  export class DialogExample {
    constructor(private dialog: MatDialog) {}

    onClickLoadData(): void {
      const dialogRef = this.dialog.open(LoaderDialogComponent);
      setTimeout(() => dialogRef.close(), 2000);
    }

    onClickDeleteProduct(): void {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: {
          message: 'Você confirma a exclusão do produto?',
          textCenter: false,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('Clicou em ' + (result ? 'Sim' : 'Não'));
      });
    }

    onClickHello(): void {
      this.dialog.open(MessageDialogComponent, {
        width: '350px',
        data: {
          message: 'Olá. Esta é uma mensagem para interação com o usuário.',
          textCenter: false,
        },
      });
    }

    onClickMoveProductToQuarantine(): void {
      const dialogRef = this.dialog.open(CheckboxListDialogComponent, {
        width: '500px',
        data: {
          title: 'Mover produto para quarentena',
          message: 'Informe as pendências para mover o produto para quarentena:',
          checkboxes: [
            {
              value: 'image',
              label: 'Imagem',
              checked: true
            },
            {
              value: 'brand',
              label: 'Marca',
              checked: true,
              disabled: true,
              tooltip: 'Resolva a divergência na marca para desmarcar este campo.'
            },
            {
              value: 'category',
              label: 'Categoria',
            },
          ],
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log(result);
        } else {
          console.log('Cancelou!');
        }
      });
    }
  }
  `;

  constructor(private dialog: MatDialog) {}

  onClickLoadData(): void {
    const dialogRef = this.dialog.open(LoaderDialogComponent);
    setTimeout(() => dialogRef.close(), 2000);
  }

  onClickDeleteProduct(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: 'Você confirma a exclusão do produto?',
        textCenter: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Clicou em ' + (result ? 'Sim' : 'Não'));
    });
  }

  onClickHello(): void {
    this.dialog.open(MessageDialogComponent, {
      width: '350px',
      panelClass: 'hauha',
      data: {
        message: 'Olá. Esta é uma mensagem para interação com o usuário.',
        textCenter: false,
      },
    });
  }

  onClickMoveProductToQuarantine(): void {
    const dialogRef = this.dialog.open(CheckboxListDialogComponent, {
      width: '500px',
      data: {
        title: 'Mover produto para quarentena',
        message: 'Informe as pendências para mover o produto para quarentena:',
        checkboxes: [
          {
            value: 'image',
            label: 'Imagem',
            checked: true
          },
          {
            value: 'brand',
            label: 'Marca',
            checked: true,
            disabled: true,
            tooltip: 'Resolva a divergência na marca para desmarcar este campo.'
          },
          {
            value: 'category',
            label: 'Categoria',
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      } else {
        console.log('Cancelou!');
      }
    });
  }
}
