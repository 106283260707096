<label class="relative">
  {{ label }}<span class="required" *ngIf="required">*</span>
  <ng-content select="[icon]"></ng-content>
</label>

<mat-form-field
  [ngClass]="classNames"
  appearance="outline"
  >

  <ng-content [ngTemplateOutlet]="content" select="[input]"></ng-content>

  <ng-container *ngIf="beforeViewInit">
    <input matInput/>
  </ng-container>

  <mat-error *ngIf="switchErrors()" [ngSwitch]="switchErrors()">
    <ng-container *ngFor="let error of getArrayErrors();">
      <span
        *ngSwitchCase="getErrorName(error)"
        [innerHTML]="getErrorMessage(error)"></span>
    </ng-container>
  </mat-error>
</mat-form-field>
