import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-alert-warning',
  templateUrl: './alert-warning.component.html',
  styleUrls: ['./alert-warning.component.scss', '../alert.component.scss'],
})
export class AlertWarningComponent {
  @Input() title: string;
  @Input() message: string;
  @Output() closeAlert = new EventEmitter();

  onClickClose(): void {
    this.closeAlert.emit();
  }
}
