<h1 class="text-size-48 text-bold margin-top-0x">Animations</h1>
<p class="text-size-24 text-regular margin-bottom-16x">Fornece algumas animações para ajudarem na interação com o usuário.</p>

<h2 class="text text-size-36 text-medium">Exemplos (selection)</h2>
<p class="text-size-18">Clique nos cards para iniciar a animação.</p>

<div class="margin-bottom-8x flex">
  <div class="example-container margin-right-3x">
    <p class="text-bold">Azul</p>
    <mat-card class="flex centered vertical" [@selectBlue]="card1Selected" (click)="card1Selected = !card1Selected">
      <div class="width-40p">
        <p class="text-bold text-size-14 margin-bottom-2x margin-top-0x">GTIN</p>
        <p class="text-bold text-size-14 margin-bottom-2x">Descrição</p>
        <p class="text-bold text-size-14 margin-bottom-2x">Marca</p>
        <p class="text-bold text-size-14 margin-bottom-0x">Categoria</p>
      </div>
      <div class="width-60p">
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1 margin-top-0x">79011111111134</p>
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café Simplus 100</p>
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Simplus</p>
        <p class="text-size-14 margin-bottom-0x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café</p>
      </div>
    </mat-card>
  </div>
  <div class="example-container margin-right-3x">
    <p class="text-bold">Vermelho</p>
    <mat-card class="flex centered vertical" [@selectRed]="card2Selected" (click)="card2Selected = !card2Selected">
      <div class="width-40p">
        <p class="text-bold text-size-14 margin-bottom-2x margin-top-0x">GTIN</p>
        <p class="text-bold text-size-14 margin-bottom-2x">Descrição</p>
        <p class="text-bold text-size-14 margin-bottom-2x">Marca</p>
        <p class="text-bold text-size-14 margin-bottom-0x">Categoria</p>
      </div>
      <div class="width-60p">
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1 margin-top-0x">79011111111134</p>
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café Simplus 100</p>
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Simplus</p>
        <p class="text-size-14 margin-bottom-0x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café</p>
      </div>
    </mat-card>
  </div>
  <div class="example-container margin-right-3x">
    <p class="text-bold">Primária</p>
    <mat-card class="flex centered vertical" [@selectPrimaryGradient]="card3Selected" (click)="card3Selected = !card3Selected">
      <div class="width-40p">
        <p class="text-bold text-size-14 margin-bottom-2x margin-top-0x">GTIN</p>
        <p class="text-bold text-size-14 margin-bottom-2x">Descrição</p>
        <p class="text-bold text-size-14 margin-bottom-2x">Marca</p>
        <p class="text-bold text-size-14 margin-bottom-0x">Categoria</p>
      </div>
      <div class="width-60p">
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1 margin-top-0x">79011111111134</p>
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café Simplus 100</p>
        <p class="text-size-14 margin-bottom-2x text-overflow ellipsis text-line-clamp-1">Simplus</p>
        <p class="text-size-14 margin-bottom-0x text-overflow ellipsis text-line-clamp-1">Suporte para Filtro de Café</p>
      </div>
    </mat-card>
  </div>
</div>

<mat-card class="margin-bottom-8x">
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="example1Html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="example1Ts" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="SCSS"><pre><code [highlight]="example1Scss" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>

<h2 class="text text-size-36 text-medium">Exemplos (show & hide)</h2>
<p class="text-size-18">Clique no botão para iniciar a animação.</p>

<div class="margin-bottom-8x">
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x margin-bottom-3x"
    (click)="showSquare = !showSquare">
    <label>{{ showSquare ? 'Ocultar' : 'Mostrar' }}</label>
  </button>
  <div class="example-square-container padding-3x" *ngIf="showSquare" [@showHide]>
    Surpresa
  </div>
</div>

<mat-card class="margin-bottom-8x">
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="example2Html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="example2Ts" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="SCSS"><pre><code [highlight]="example2Scss" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>

<h2 class="text text-size-36 text-medium">Exemplos (rotate & slide up/down)</h2>
<p class="text-size-18">Clique no card para iniciar a animação.</p>

<div class="margin-bottom-8x flex">
  <div class="example-container margin-right-3x">
    <mat-card (click)="showContent = !showContent">
      <mat-icon
        aria-hidden="false"
        aria-label="Expandir"
        matTooltip="Expandir"
        class="icon margin-right-3x"
        [inline]="true"
        [@rotate90Right]="showContent">keyboard_arrow_right</mat-icon>
      Expandir
    </mat-card>
    <div *ngIf="showContent" [@slideUpDown] class="example-expansion-container padding-3x">
      Surpresa
    </div>
  </div>
</div>

<mat-card class="margin-bottom-8x">
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="example3Html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="example3Ts" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="SCSS"><pre><code [highlight]="example3Scss" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>

<h2 class="text text-size-36 text-medium">Exemplos (slide left/right)</h2>
<p class="text-size-18">Clique nos botões para navegar entre as telas.</p>

<div class="margin-bottom-8x">
  <div class="example-slider-container margin-bottom-3x padding-3x" [@slideLeftRight]="contentIndex">
    <div class="example-slide-container example-slide-container-1" *ngIf="contentIndex === 0">AAAA</div>
    <div class="example-slide-container example-slide-container-2" *ngIf="contentIndex === 1">BBBB</div>
    <div class="example-slide-container example-slide-container-3" *ngIf="contentIndex === 2">CCC</div>
  </div>

  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x margin-right-5x"
    (click)="onClicPrevious()"
    [disabled]="disablePrevious()">
    <label>Anterior</label>
  </button>
  <button
    mat-flat-button
    type="button"
    class="button text-uppercase text-regular height-10x"
    (click)="onClickNext()"
    [disabled]="disableNext()">
    <label>Proxímo</label>
  </button>
</div>

<mat-card class="margin-bottom-8x">
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="example4Html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="example4Ts" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="SCSS"><pre><code [highlight]="example4Scss" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>
