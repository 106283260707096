import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LoaderDialogComponent } from './loader-dialog/loader-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { CheckboxListDialogComponent } from './checkbox-list-dialog/checkbox-list-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from '../alert/alert.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    LoaderDialogComponent,
    MessageDialogComponent,
    CheckboxListDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    AlertModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true, hasBackdrop: true },
    },
  ],
  exports: [
    ConfirmationDialogComponent,
    LoaderDialogComponent,
    MessageDialogComponent,
    CheckboxListDialogComponent,
  ],
})
export class DialogModule {}
