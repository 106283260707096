<div mat-dialog-content>
  <mat-icon
    id="close-icon"
    matTooltip="Fechar"
    i18n-matTooltip="@@close"
    (click)="onClickClose()">close</mat-icon>

  <p
    [innerHTML]="data.message"
    class="text-size-16 margin-top-3x margin-bottom-5x"
    [ngStyle]="{'text-align': data.textCenter ? 'center' : 'left'}"></p>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="button text-uppercase text-medium padding-left-11x padding-right-11x"
    (click)="onClickClose()">Ok</button>
</div>
