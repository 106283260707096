import {
  trigger,
  transition,
  state,
  style,
  animate,
} from '@angular/animations';

export const ROTATE_90_RIGHT_ANIMATION = trigger('rotate90Right', [
  state('false', style({ transform: 'rotate(0)' })),
  state('true', style({ transform: 'rotate(90deg)' })),
  transition('true => false', animate('200ms ease-out')),
  transition('false => true', animate('200ms ease-in')),
]);
