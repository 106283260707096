import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss'],
})
export class ScrollbarComponent {
  alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  html = `  <div class="flex">
    <div class="example-container overflow-auto margin-right-3x">
      <p>Normal scrollbar</p>
      <p *ngFor="let item of alphabet">{{ item }}</p>
    </div>

    <perfect-scrollbar class="example-container margin-right-3x">
      <p>Perfect scrollbar (component)</p>
      <p *ngFor="let item of alphabet">{{ item }}</p>
    </perfect-scrollbar>

    <div class="example-container margin-right-3x" perfectScrollbar>
      <p>Perfect scrollbar (directive)</p>
      <p *ngFor="let item of alphabet">{{ item }}</p>
    </div>

    <perfect-scrollbar class="example-container ps-show-always">
      <p>Perfect scrollbar (component). Sempre mostra a barra de rolagem. Não funciona com directive.</p>
      <p *ngFor="let item of alphabet">{{ item }}</p>
    </perfect-scrollbar>
  </div>`;
  ts = `  import { Component } from '@angular/core';

  @Component({
    selector: 'scrollbar-example',
    templateUrl: 'scrollbar-example.html',
    styleUrls: ['./scrollbar-example.scss'],
  })
  export class ScrollbarExample {
    alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  }`;
  scss = `  .example-container {
    width: 250px;
    height: 250px;
    background: lemonchiffon;
    position: relative;
  }`;
}
