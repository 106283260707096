import { Component } from '@angular/core';

@Component({
  selector: 'app-loaders',
  templateUrl: './loaders.component.html',
  styleUrls: ['./loaders.component.scss'],
})
export class LoadersComponent {
  html = `  <div class="flex">
    <div class="example-container margin-right-3x">
      <ui-loader></ui-loader>
    </div>
    <div class="example-container margin-right-3x">
      <!-- Works if parent element position is defined. -->
      <ui-loader [forcePositionCenter]="true"></ui-loader>
    </div>
    <div class="example-container">
      <!-- Works if parent element height is defined. -->
      <ui-loader [fullHeight]="true"></ui-loader>
    </div>
  </div>`;
  ts = `  import { Component } from '@angular/core';

  @Component({
    selector: 'loader-example',
    templateUrl: 'loader-example.html',
    styleUrls: ['./loader-example.scss'],
  })
  export class LoaderExample {}`;
  scss = `  .example-container {
    width: 250px;
    height: 250px;
    background: lemonchiffon;
    position: relative;
  }`;
}
