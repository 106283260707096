import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-alert-success',
  templateUrl: './alert-success.component.html',
  styleUrls: ['./alert-success.component.scss', '../alert.component.scss'],
})
export class AlertSuccessComponent {
  @Input() title: string;
  @Input() message: string;
}
