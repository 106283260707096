import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AlertsComponent } from './component/alerts/alerts.component';
import { AnimationsComponent } from './component/animations/animations.component';
import { DialogsComponent } from './component/dialogs/dialogs.component';
import { FormFieldsComponent } from './component/form-fields/form-fields.component';
import { LoadersComponent } from './component/loaders/loaders.component';
import { ScrollbarComponent } from './component/scrollbar/scrollbar.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'alerts',
  },
  {
    path: 'alerts',
    component: AlertsComponent,
  },
  {
    path: 'animations',
    component: AnimationsComponent,
  },
  {
    path: 'dialogs',
    component: DialogsComponent,
  },
  {
    path: 'form-fields',
    component: FormFieldsComponent,
  },
  {
    path: 'loaders',
    component: LoadersComponent,
  },
  {
    path: 'scrollbar',
    component: ScrollbarComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
