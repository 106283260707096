import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogDataInterface } from './confirmation-dialog-data.interface';

@Component({
  selector: 'ui-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: [
    './confirmation-dialog.component.scss',
    '../dialog.component.scss',
  ],
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogDataInterface
  ) {}

  onClickNo(): void {
    this.dialogRef.close(false);
  }
}
