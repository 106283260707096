import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.scss'],
})
export class FormFieldsComponent implements OnInit {
  form: FormGroup;

  html = `  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <button
      mat-flat-button
      type="button"
      class="button text-uppercase text-regular height-10x margin-bottom-3x"
      (click)="onClickToggleDisabled()">
      <label>{{ form.disabled ? 'Habilitar': 'Desabilitar' }} Formulário</label>
    </button>

    <div>
      <label class="title">GTIN</label>
      <mat-form-field appearance="outline" class="input-outline">
        <input
            matInput
            name="gtin"
            id="gtin"
            formControlName="gtin">
      </mat-form-field>
    </div>

    <div class="flex centered vertical">
      <hr class="grow separator">
      <button
        mat-flat-button
        type="submit"
        class="button text-uppercase text-regular height-10x margin-left-3x"
        color="accent">
        <label>Salvar</label>
      </button>
    </div>

  </form>`;
  ts = `  import { Component, OnInit } from '@angular/core';
  import { FormControl, FormGroup } from '@angular/forms';

  @Component({
    selector: 'form-example',
    templateUrl: 'form-example.html',
  })
  export class FormExample implements OnInit {
    form: FormGroup;

    ngOnInit(): void {
      this.form = new FormGroup({
        gtin: new FormControl()
      });
    }

    onSubmit(): void {
      console.log(this.form.value);
    }

    onClickToggleDisabled() {
      if (this.form.disabled) {
        this.form.enable();
      } else if (this.form.enabled) {
        this.form.disable();
      }
    }
  }`;

  ngOnInit(): void {
    this.form = new FormGroup({
      gtin: new FormControl(),
    });
  }

  onSubmit(): void {
    console.log(this.form.value);
  }

  onClickToggleDisabled(): void {
    if (this.form.disabled) {
      this.form.enable();
    } else if (this.form.enabled) {
      this.form.disable();
    }
  }
}
