import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormControlName,
  FormGroup,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';

@Component({
  selector: 'ui-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css'],
})
export class FormFieldComponent implements OnInit {
  @Input() label: string;

  @Input() required?: boolean = false;

  @Input() classNames?: string[] = ['input-outline'];

  @Input() errors? = {};

  @Input() submitted?: boolean = false;

  form: FormGroup;
  control: FormControl;

  private defaultErrors = {
    required: $localize`:Ex\: Marca é obrigatório(a).@@fieldIsMandatory:#{label} é obrigatório(a).`,
    minlength: $localize`:Ex\: Nome deve ter ao menos 10 caracteres.@@fieldMustHaveAtLeastXCharacters:#{label} deve ter ao menos #{length} caracteres.`
  };

  private controlName: string;

  beforeViewInit = true;
  @ContentChild(MatFormFieldControl)
  matFormFieldControl: MatFormFieldControl<any>;
  @ViewChild(MatFormField) matFormField: MatFormField;
  @ContentChild(FormControlName) formControlName: FormControlName;

  constructor(
    private controlContainer: ControlContainer,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
  }

  ngAfterViewInit() {
    if (this.beforeViewInit) {
      this.matFormField._control = this.matFormFieldControl;
      this.beforeViewInit = false;
      this.changeDetectorRef.detectChanges();

      this.controlName = `${this.formControlName.name}`;
      this.control = <FormControl>this.form.get(this.controlName);
    }
  }

  switchErrors(): string {
    const input = this.form.get(this.controlName);
    if (input && (input.touched || this.submitted) && input.errors) {
      return Object.keys(input.errors)[0];
    }
    return '';
  }

  getArrayErrors() {
    const errors = [];

    Object.entries(this.errors).forEach((error) => {
      errors.push({ [error[0]]: error[1] });
    });

    Object.entries(this.defaultErrors).forEach((defaultError) => {
      const errorIndex = Object.entries(this.errors).findIndex(
        (error) => error[0] === defaultError[0]
      );

      if (errorIndex < 0) {
        errors.push({ [defaultError[0]]: defaultError[1] });
      }
    });

    return errors;
  }

  getErrorMessage(error) {
    let message = Object.entries(this.errors).find(
      (errorItem) => errorItem[0] === this.getErrorName(error)
    );

    if (!message) {
      message = Object.entries(this.defaultErrors).find(
        (errorItem) => errorItem[0] === this.getErrorName(error)
      );
    }

    if (
      message &&
      !message[1] &&
      this.form &&
      this.form.controls[this.controlName]
    ) {
      message[1] =
        this.form.get(this.controlName).errors[this.getErrorName(error)] || '';
    }

    return this.getFormattedErrorMessage(message[1]);
  }

  getErrorName(error) {
    return Object.keys(error)[0];
  }

  getFormattedErrorMessage(message) {
    return message
      .replace('${label}', this.label)
      .replace('${length}', this.getRequiredLength());
  }

  getRequiredLength() {
    if (this.form && this.form.controls[this.controlName]) {
      const validators = this.form
        .get(this.controlName)
        .validator(this.control);

      if (validators && validators.hasOwnProperty('minlength')) {
        return validators['minlength'].requiredLength;
      }
    }

    return '';
  }
}
