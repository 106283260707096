import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const SELECT_BLUE_ANIMATION = trigger('selectBlue', [
  state(
    'false',
    style({
      'background-color': 'white',
      border: '1px solid #DFDFDF',
    })
  ),
  state(
    'true',
    style({
      'background-color': '#D9EFF6',
      border: '1px solid #46C876',
    })
  ),
  transition('false <=> true', animate(300)),
]);

export const SELECT_RED_ANIMATION = trigger('selectRed', [
  state(
    'false',
    style({
      'background-color': 'white',
      border: '1px solid #DFDFDF',
    })
  ),
  state(
    'true',
    style({
      'background-color': '#F450070D',
      border: '1px solid #F45007',
    })
  ),
  transition('false <=> true', animate(300)),
]);

export const SELECT_PRIMARY_GRADIENT_ANIMATION = trigger('selectPrimaryGradient', [
  state(
    'false',
    style({
      'background': 'white',
      color: 'black',
    })
  ),
  state(
    'true',
    style({
      'background': 'transparent linear-gradient(90deg, #F34C07 0%, #FF8800 100%) 0% 0% no-repeat padding-box',
      color: 'white',
    })
  ),
  transition('false <=> true', animate(300)),
]);
