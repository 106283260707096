import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertDangerComponent } from './alert-danger/alert-danger.component';
import { AlertSuccessComponent } from './alert-success/alert-success.component';
import { MatIconModule } from '@angular/material/icon';
import { AlertWarningComponent } from './alert-warning/alert-warning.component';

@NgModule({
  declarations: [AlertDangerComponent, AlertSuccessComponent, AlertWarningComponent],
  imports: [CommonModule, MatIconModule],
  exports: [AlertDangerComponent, AlertSuccessComponent, AlertWarningComponent],
})
export class AlertModule {}
