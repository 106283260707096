import { Component } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent {
  showAlertDanger = true;
  showAlertWarning = true;
  html = `  <ui-alert-danger
    title="Perigo"
    message="Este é um alerta de perigo!"
    *ngIf="showAlertDanger"
    (closeAlert)="showAlertDanger = false"
    class="margin-bottom-3x"></ui-alert-danger>

  <ui-alert-success
    title="Sucesso"
    message="Este é um alerta de sucesso!"
    class="margin-bottom-3x"></ui-alert-success>
  
  <ui-alert-warning
    title="Aviso"
    message="Este é um alerta de aviso!"
    *ngIf="showAlertWarning"
    (closeAlert)="showAlertDanger = false"></ui-alert-warning>
  `;
  ts = `  import {Component} from '@angular/core';

  @Component({
    selector: 'alert-example',
    templateUrl: 'alert-example.html',
  })
  export class AlertExample {
    showAlertDanger = true;
    showAlertWarning = true;
  }
  `;
}
