<h1 class="text-size-48 text-bold margin-top-0x">Scrollbar</h1>
<p class="text-size-24 text-regular margin-bottom-16x">Barra de rolagem em containers com muito conteúdo.</p>

<h2 class="text text-size-36 text-medium">Exemplos</h2>
<p class="text-size-18">Utilizamos a barra de rolagem (perfect-scrollbar).</p>

<div class="margin-bottom-8x flex">
  <div class="example-container overflow-auto margin-right-3x">
    <p>Normal scrollbar</p>
    <p *ngFor="let item of alphabet">{{ item }}</p>
  </div>

  <perfect-scrollbar class="example-container margin-right-3x">
    <p>Perfect scrollbar (component)</p>
    <p *ngFor="let item of alphabet">{{ item }}</p>
  </perfect-scrollbar>

  <div class="example-container margin-right-3x" perfectScrollbar>
    <p>Perfect scrollbar (directive)</p>
    <p *ngFor="let item of alphabet">{{ item }}</p>
  </div>

  <perfect-scrollbar class="example-container ps-show-always">
    <p>Perfect scrollbar (component). Sempre mostra a barra de rolagem. Não funciona com directive.</p>
    <p *ngFor="let item of alphabet">{{ item }}</p>
  </perfect-scrollbar>
</div>

<mat-card>
  <mat-tab-group>
    <mat-tab label="HTML"><pre><code [highlight]="html" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="TS"><pre><code [highlight]="ts" [lineNumbers]="true"></code></pre></mat-tab>
    <mat-tab label="SCSS"><pre><code [highlight]="scss" [lineNumbers]="true"></code></pre></mat-tab>
  </mat-tab-group>
</mat-card>
