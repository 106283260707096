import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {

  form: FormGroup;
  submitted: boolean = false;

  html = `  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <button
      mat-flat-button
      type="button"
      class="button text-uppercase text-regular height-10x margin-bottom-3x"
      (click)="onEmitValidationError()">
      <label>Emitir erro de validação</label>
    </button>

    <div>
      <ui-form-field
        label="GTIN"
        [submitted]="submitted"
        [errors]="{
          invalid: 'Formato inválido'
        }">
        <mat-icon
          [inline]="true"
          class="text-size-18 margin-left-1x color-emerald absolute top-negative-1x"
          icon>check_circle</mat-icon>
        <input
          matInput
          name="gtin"
          id="gtin"
          formControlName="gtin"
          input>
      </ui-form-field>
    </div>

    <div class="flex centered vertical">
      <hr class="grow separator">
      <button
        mat-flat-button
        type="submit"
        class="button text-uppercase text-regular height-10x margin-left-3x"
        color="accent">
        <label>Salvar</label>
      </button>
    </div>

  </form>`;

  ts = `  import { Component, OnInit } from '@angular/core';
  import { FormControl, FormGroup, Validators } from '@angular/forms';

  @Component({
    selector: 'app-smp-form-field',
    templateUrl: './smp-form-field.component.html',
    styleUrls: ['./smp-form-field.component.scss']
  })
  export class SmpFormFieldComponent implements OnInit {

    form: FormGroup;
    submitted: boolean = false;

    onSubmit(): void {
      this.submitted = true;

      if (this.form.valid) {
        console.log(this.form.value);
      }
    }

    onEmitValidationError(): void {
      this.submitted = true;
      this.form.controls['gtin'].setErrors({
        invalid: 'Formato inválido',
      });
      this.form.controls['gtin'].markAsTouched();
    }

    constructor() { }

    ngOnInit(): void {
      this.form = new FormGroup({
        gtin: new FormControl(null, [
          Validators.required,
          Validators.minLength(8)
        ]),
      });
    }
  }`;

  onSubmit(): void {
    this.submitted = true;

    if (this.form.valid) {
      console.log(this.form.value);
    }
  }

  onEmitValidationError(): void{
    this.submitted = true;
    this.form.controls['gtin'].setErrors({
      invalid: 'Formato inválido',
    });
    this.form.controls['gtin'].markAsTouched();
  }

  constructor() { }

  ngOnInit(): void {
    this.form = new FormGroup({
      gtin: new FormControl(null, [
        Validators.required,
        Validators.minLength(8)
      ]),
    });
  }
}
