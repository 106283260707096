<div>
  <mat-icon
    matTooltip="Fechar"
    i18n-matTooltip="@@close"
    (click)="onClickClose()"
    >close</mat-icon
  >
  <p>{{ title }}</p>
  <p [innerHTML]="message"></p>
</div>
