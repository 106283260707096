import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckboxListDialogDataInterface } from './checkbox-list-dialog-data.interface';

@Component({
  selector: 'app-checkbox-list-dialog',
  templateUrl: './checkbox-list-dialog.component.html',
  styleUrls: [
    './checkbox-list-dialog.component.scss',
    '../dialog.component.scss',
  ],
})
export class CheckboxListDialogComponent implements OnInit {
  showAlertDanger: boolean;
  checkboxForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CheckboxListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CheckboxListDialogDataInterface
  ) {}

  ngOnInit(): void {
    this.checkboxForm = new FormGroup({}, this.validateOneChecked.bind(this));
    this.data.checkboxes.forEach((checkbox) => {
      this.checkboxForm.addControl(
        checkbox.value,
        new FormControl({
          value: checkbox.checked,
          disabled: checkbox.disabled,
        })
      );
    });
  }

  validateOneChecked(): object {
    if (this.checkboxForm) {
      const hasTrue = Object.values(this.checkboxForm.getRawValue()).includes(
        true
      );
      if (!hasTrue) {
        return { required: true };
      }
    }

    return null;
  }

  onSubmit(form: FormGroupDirective): void {
    this.showAlertDanger = false;
    if (this.checkboxForm.valid) {
      this.dialogRef.close(this.checkboxForm.getRawValue());
    } else {
      this.showAlertDanger = true;
    }
  }

  onClickClose(): void {
    this.dialogRef.close(false);
  }
}
