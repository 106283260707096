import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-alert-danger',
  templateUrl: './alert-danger.component.html',
  styleUrls: ['./alert-danger.component.scss', '../alert.component.scss'],
})
export class AlertDangerComponent {
  @Input() title: string;
  @Input() message: string;
  @Output() closeAlert = new EventEmitter();

  onClickClose(): void {
    this.closeAlert.emit();
  }
}
