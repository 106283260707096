<div id="container" class="flex column">
  <mat-toolbar class="background-gray-eclipse">
    <img src="assets/logo-simplus-header.png" alt="">
    <span class="color-white text-size-24">&nbsp;User Interface</span>
  </mat-toolbar>

  <mat-sidenav-container class="grow">
    <mat-sidenav mode="side" opened>
      <mat-nav-list>
        <a mat-list-item routerLink="/alerts"> Alerts </a>
        <a mat-list-item routerLink="/animations"> Animations </a>
        <a mat-list-item routerLink="/dialogs"> Dialogs </a>
        <a mat-list-item routerLink="/form-fields"> Form Fields </a>
        <a mat-list-item routerLink="/loaders"> Loaders </a>
        <a mat-list-item routerLink="/scrollbar"> Scrollbar </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="padding-16x">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
